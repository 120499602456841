


























































































import { defineComponent } from "@vue/composition-api";
import { mapStores } from "pinia";
import { mapActions } from "vuex";
import { coachApi } from "@/lib/backend";
import type { FitnessAfspraakExtern } from "@/lib/backend/coach.api";
import { DEFAULT_TRACKING } from "@/lib/constants/misc";
import { APPOINTMENT_MAP } from "@/lib/constants/options";
import { useGebruikerStore } from "@/pinia/gebruiker";
import { usePopupStore } from "@/pinia/popup";
import {
	getDateFromDatum,
	getDayMonth,
	getDayMonthYear,
	getTimeOfDay,
	getWeekDay,
} from "@/utils/date";

type Data = {
	loading: boolean;
	selectedAfspraak?: FitnessAfspraakExtern;
};

export default defineComponent({
	name: "PfgGebruikerSchema",
	props: {},
	data(): Data {
		return {
			loading: false,
			selectedAfspraak: undefined,
		};
	},
	computed: {
		...mapStores(useGebruikerStore, usePopupStore),
		isTracking() {
			return (
				// Because typescript complains
				(this.gebruikerStore as unknown as { tracking: { schemas: boolean } }).tracking?.schemas ??
				DEFAULT_TRACKING
			);
		},
	},
	watch: {
		$route: "check",
	},
	async created() {
		await this.check();
	},
	methods: {
		...mapActions("afspraak", ["cancelAppointment"]),
		async check() {
			this.loading = true;

			const { id } = this.$route.params;

			const numberId = Number(id);

			if (isNaN(numberId)) return;

			const currentId = this.gebruikerStore.id;

			if (currentId !== numberId) this.gebruikerStore.set(numberId);

			await this.getRequiredInfo();
			await this.gebruikerStore.getTracking();

			this.loading = false;
		},
		async getRequiredInfo() {
			await this.gebruikerStore.getAppointmentsBooked();
		},
		async changeTracking(active: boolean) {
			await this.gebruikerStore.updateTracking({
				value: active,
				type: "schemas",
			});

			await this.getRequiredInfo();
		},
		openOpmerking(afspraak: FitnessAfspraakExtern) {
			this.popupStore.open({
				title: "Opmerking",
				body: afspraak.aanwezigheid?.opmerkingen || "",
				buttons: {
					cancel: "Sluiten",
				},
			});
		},
		cancelPopup(afspraak: FitnessAfspraakExtern) {
			const body = `Weet je zeker dat je de afspraak wilt annuleren
            voor <b>${afspraak.aanwezigheid?.lidNaam}</b>
            op <b>${getDayMonthYear(new Date(afspraak.datum))}</b>?`;

			this.popupStore.open({
				title: "Let op!",
				body,
				buttons: {
					cancel: "Sluiten",
					confirm: "Afspraak annuleren",
				},
				callback: async () => {
					await this.cancelAfspraak(afspraak);
				},
			});
		},
		async cancelAfspraak(afspraak: FitnessAfspraakExtern) {
			try {
				const aanwezigheid = afspraak.aanwezigheid;

				if (!aanwezigheid) {
					throw new Error("Geen afspraak aanwezigheid");
				}

				const response = await coachApi.api.appointmentsDoneCreate(afspraak.id, {
					...aanwezigheid,
					lidId: +aanwezigheid.lidId,
					lidNaam: aanwezigheid.lidNaam ?? "",
					status: "afgemeld",
				});

				switch (response.status) {
					case 200: {
						await this.getRequiredInfo();

						return;
					}

					default: {
						throw response;
					}
				}
			} catch (error: { message: string } | string | unknown) {
				const message = (error as { message: string })?.message || error;

				this.popupStore.showError(
					`Er ging iets mis bij het annuleren van de afspraak: ${message}.<br/>Probeer het later nog eens.`,
				);
			}
		},
		getAppointmentText({ aanwezigheid }: FitnessAfspraakExtern): string {
			if (!aanwezigheid) return "";

			const values = Object.entries(aanwezigheid)
				.filter(([key]) => Object.keys(APPOINTMENT_MAP).includes(key))
				.filter(([, value]) => value === true)
				.map(([key]) => APPOINTMENT_MAP[key].shortText);

			if (values.length === 0) return "";

			// eslint-disable-next-line
			// @ts-ignore - ListFormat is not correctly typed within compiler TS
			return new Intl.ListFormat("nl", {
				style: "long",
				type: "conjunction",
			}).format(values);
		},
		getWeekDay,
		getDayMonth,
		getTimeOfDay,
		getDateFromDatum,
	},
});
